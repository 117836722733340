import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CSS/Gallery.css';

const images = [
  { src: 'asian-elderly-man-feeling-happy-smiling-looking-camera-while-relax-kitchen-home-lifestyle-senior-men-home-concept.jpg', alt: 'memory 1' },
  { src: 'lovely-portrait-father-son-celebration-father-s-day.jpg', alt: 'memory 2' },
  { src: 'grandmother-playing-with-granddaughter-outdoors-park.jpg', alt: 'memory 3' },
  { src: 'medium-shot-family-with-delicious-chocolate.jpg', alt: 'memory 4' },
  { src: 'medium-shot-old-woman-library.jpg', alt: 'memory 5' },
  { src: 'mother-daughter-sitting-floor.jpg', alt: 'memory 6' },
  { src: 'happy-senior-old-asian-lover-couple-holding-smartphone-looking-cellphone-screen-laughing-casual-relaxing-sit-sofa-together-smiling-elder-mature-grandparents-family-embracing-lifestyle.jpg', alt: 'memory 7' },
  { src: 'fist-bump-asian-father-son.jpg', alt: 'memory 8' },
];

const data = [
  {
    name: 'Michael Jackson',
    dob: 'Nov 25, 1958',
    dod: 'May 14, 2023',
    img: 'mj.png',
  },
  {
    name: 'Coco Lee',
    dob: 'Jan 17, 1975',
    dod: 'Jul 5, 2023',
    img: 'cocolee.png',
  },
  {
    name: 'Tina Turner',
    dob: 'Nov 26, 1939',
    dod: 'May 24, 2023',
    img: 'tina.png',
  },
  {
    name: 'Leslie Cheung',
    dob: 'Sep 12, 1956',
    dod: 'Apr 1, 2003',
    img: 'leslie.png',
  },
  {
    name: 'Robin Williams',
    dob: 'Jul 21, 1951',
    dod: 'Aug 11, 2014',
    img: 'robin.png',
  },
  {
    name: 'TinaTurner',
    dob: 'Nov 26, 1939',
    dod: 'May 24, 2023',
    img: 'tina.png',
  },
  {
    name: 'LeslieCheung',
    dob: 'Sep 12, 1956',
    dod: 'Apr 1, 2003',
    img: 'leslie.png',
  },
  {
    name: 'RobinWilliams',
    dob: 'Jul 21, 1951',
    dod: 'Aug 11, 2014',
    img: 'robin.png',
  }
];

const Gallery = () => {
  const [query, setQuery] = useState('');

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    setFilteredPersons(data)
    console.log('Searching for:', query);
  };

  const [selectedPerson, setSelectedPerson] = useState(data[0]);
  const [filteredPersons, setFilteredPersons] = useState(data);
  const [copied, setCopied] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleApply = () => {
    alert(`Start Date: ${startDate}, End Date: ${endDate}`);
  };
  
  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleClose = () => {
    setActiveImageIndex(null);
  };

  const handleCopy = () => {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

    navigator.clipboard.writeText(baseUrl+"/obituary")
      .then(() => {
        setCopied(true);  // Update state to indicate success
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(err => {
        console.error("Failed to copy: ", err);
      });
  };

  const ForArrow = () => {
    if (window.innerWidth >= 768) {
      return (
        <div className="obituary-details">
          <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
          <div className="obituary-page-recent-obituaries">
            <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow'/></button>
            <ul className="obituary-list">
              {filteredPersons.map((person, index) => (
                <li 
                  key={index} 
                  className={`obituary-item ${person.name === selectedPerson.name ? 'active' : ''}`} 
                  onClick={() => setSelectedPerson(person)}
                >
                  <div className="obituary-card" key={index}>
                    <img src={person.img} alt={person.name} className="obituary-image" />
                    <div className='obituary-card-info'>
                      <span className='obituary-card-info-span-1'>In loving memory of</span>
                      <h3 className='obituary-card-h3'>{person.name}</h3>
                      <p className='obituary-card-p'>{person.dob} - {person.dod}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow'/></button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="obituary-details">
          <div className="obituary-page-recent-obituaries">
          <ul className="obituary-list">
              {filteredPersons.map((person, index) => (
                <li 
                  key={index} 
                  className={`obituary-item ${person.name === selectedPerson.name ? 'active' : ''}`} 
                  onClick={() => setSelectedPerson(person)}
                >
                  <div className="obituary-card" key={index}>
                    <img src={person.img} alt={person.name} className="obituary-image" />
                    <div className='obituary-card-info'>
                      <h3 className='obituary-card-h3'>{person.name}</h3>
                      <p className='obituary-card-p'>{person.dob} - {person.dod}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='recent-obituaries-nav-button-container'>
            <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
            <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="Gallery">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <h1 className='gallery-h1'>Memories with the loves</h1>
      <span className='gallery-text'>Search the cherished memories of your loved one. Enter a name to view photos, stories, and tributes shared by family and friends.</span>
      <div className='obituary-side-by-side'>
        <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='obituary-search-bar' />
        <button onClick={handleSearch} className='obituary-search-button'>Search</button>
        <div className='obituary-inputContainer'>
          <label className='obituary-label'>Start Date:</label>
          <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className='obituary-inputDate'
          />
          <label className='obituary-label'>End Date:</label>
          <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className='obituary-inputDate'
          />
          <button onClick={handleApply} className='obituary-applyButton'>Apply</button>
        </div>
      </div>
      <div className='gallery-body'>
        <ForArrow />
        <div className="content">
          <div className='gallery-side-by-side'>
            <h1 className='the-gallery'>The Gallery</h1>
          </div>
          <div className='gallery-buttons'>
            <Link to="/fss" state={{ props: images }}><img src='playbtn.png' alt='playbtn' /></Link>
            <button onClick={handleCopy} className='gallery-share-btn'><img src='sharebtn.png' alt='sharebtn' /></button>
            {copied && <p>Copied to clipboard!</p>}
          </div>
          <div className="gallery-gallery">
            {images.map((image, index) => (
              <div
                className={`gallery-image-container ${activeImageIndex === index ? "fullscreen" : ""}`}
                onClick={() => handleImageClick(index)}
                key={index}
              >
                <img
                  src={image.src}
                  className={`gallery-image ${activeImageIndex === index ? "expanded" : "thumbnail"}`}
                  alt={image.alt}
                  thumbnailwidth={300}
                />
                {activeImageIndex === index && (
                  <span className="close-btn" onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                  }}>✖</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;