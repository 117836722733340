import '../CSS/PartnerContact.css';

function PartnerContact() {
  return (
    <div className="PartnerContact">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <div className="partnerContact-side-by-side">
        <div className="partner">
          <div className="partner-big-text">
            <span>Partner with us</span>
          </div>
          <div className="partner-small-text">
            <div className='pst-text-1'>If you are a funeral director or work in a related field, we invite you to partner with us. Connect with us to provide comprehensive and compassionate services to families during their time of need.</div>
          </div>
        </div>
        <div className="contact">
          <div className="contact-title-text">
            <span>Contact us for more information</span>
          </div>
          <div className="contact-info-text">
            <div className='cit-text-1'>+65010101010101 or email to xxx@xxx.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerContact;