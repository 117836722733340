import { Link } from 'react-router-dom';
import '../CSS/Intro.css';

function Intro() {
  return (
    <div className="Intro">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <div className="intro-image">
        <img src="grandparent-s-day-celebration-scene-with-grandparents-grandchildren-showcasing-happy-family.png" alt="Family"></img>
      </div>
      <div className="intro-text">
        <div className="intro-bigText">
          Singapore Ancestry: Honouring Lives, Cherishing Memories
        </div>
        <div className="intro-smallText">
          The people in our lives are unique and remarkable. Even the seemingly ordinary have extraordinary qualities. Honour your loved ones with personalised online obituaries.
        </div>
        <div className='intro-sec2'>
          <div className='intro-b4stacks4'>
            <div className='intro-bigText'>
              10+
            </div>
            <div className='smallText'>
              Compassionate Team
            </div>
          </div>
          <div className='intro-stacks4'>
            <img src='4stacks.png' alt="something"></img>
          </div>
        </div>
        <div className='intro-getStartedBtn'>
          <Link to="/login"><button>Get started</button></Link>
        </div>
      </div>
    </div>
  );
}

export default Intro;