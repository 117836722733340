import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Home';
import Obituary from './Obituary';
import Gallery from './Gallery';
import Header from './Component/Header';
import Footer from './Component/Footer';

import FullScreenSlideshow from './FullScreenSlideshow';

import Navbar from './Component/Navbar';
// import Path1 from './Path1';
// import Path2 from './Path2';

function App() {
  const location = useLocation();

  const noHeaderFooterRoutes = ['/fss','/login'];

  // const header2Footer2Routes = ['/path1', '/path2'];

  return (
    <>
      {/* {!noHeaderFooterRoutes.includes(location.pathname) && !header2Footer2Routes.includes(location.pathname) && <Header />} */}

      {!noHeaderFooterRoutes.includes(location.pathname) && <Header />}
      
      {/* {header2Footer2Routes.includes(location.pathname) && <Navbar />} */}

      <Routes>
        <Route path="/fss" element={<FullScreenSlideshow />} />
        <Route path="/login" element={<FullScreenSlideshow />} />
        <Route path="/" element={<Home />} />
        <Route path="/obituary" element={<Obituary />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/path1" element={<Path1 />} />
        <Route path="/path2" element={<Path2 />} /> */}
      </Routes>

      {/* {!noHeaderFooterRoutes.includes(location.pathname) && !header2Footer2Routes.includes(location.pathname) && <Footer />} */}
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <div className="App">
        <App />
      </div>
    </Router>
  );
}

export default AppWrapper;