import '../CSS/Steps.css';

function Steps() {
  return (
    <div className="Steps">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <div className="steps-side-by-side">
        <div className="step1">
          <div className="step1-number">
            <span>01</span>
          </div>
          <div className="step1-text">
            <div className='step1-text-1'>Create and Publish Obituary</div>
            <div className='step1-text-2'>Create your loved one's obituary to honor their memory and inform family and friends.</div>
          </div>
        </div>
        <div className="step2">
          <div className="step2-number">
            <span>02</span>
          </div>
          <div className="step2-text">
            <div className='step2-text-1'>Upload Memories</div>
            <div className='step2-text-2'>Upload photos and videos to the obituary profile to keep and share precious memories.</div>
          </div>
        </div>
        <div className="step3">
          <div className="step3-number">
            <span>03</span>
          </div>
          <div className="step3-text">
            <div className='step3-text-1'>Easy Editing and Sharing</div>
            <div className='step3-text-2'>Easily edit and share the obituary with friends and family.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;