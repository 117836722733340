import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CSS/Obituary.css';
// import LinkPreview from './Component/LinkPreview';

const recentObituaries = [
    {
        name: "Michael Jackson",
        dob: "Aug 29, 1958",
        dod: "June 25, 2009",
        image: "mj.png"
    },
    {
        name: "Leslie Chung",
        dob: "Feb 12, 1980",
        dod: "Mar 15, 2024",
        image: "leslie.png" // Replace with actual image URL
    },
    {
        name: "Robin Williams",
        dob: "Jul 21, 1951, 1985",
        dod: "Aug 11, 2014",
        image: "robin.png" // Replace with actual image URL
    },
    {
        name: "Coco Lee",
        dob: "Jan 17, 1975",
        dod: "Jul 5, 2023",
        image: "cocolee.png" // Replace with actual image URL
    },
    {
        name: "MichaelJackson",
        dob: "Aug 29, 1958",
        dod: "June 25, 2009",
        image: "mj.png"
    },
    {
        name: "LeslieChung",
        dob: "Feb 12, 1980",
        dod: "Mar 15, 2024",
        image: "leslie.png" // Replace with actual image URL
    },
    {
        name: "RobinWilliams",
        dob: "Jul 21, 1951, 1985",
        dod: "Aug 11, 2014",
        image: "robin.png" // Replace with actual image URL
    },
    {
        name: "CocoLee",
        dob: "Jan 17, 1975",
        dod: "Jul 5, 2023",
        image: "cocolee.png" // Replace with actual image URL
    }
];

const Obituary = () => {
    const [selectedPerson, setSelectedPerson] = useState(recentObituaries[0]);
    const [filteredPersons, setFilteredPersons] = useState(recentObituaries);
    const [query, setQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [copied, setCopied] = useState(false);

    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const newLink = `${baseUrl}/obituary`;

    const handleCopy = () => {
        navigator.clipboard.writeText(newLink)
        .then(() => {
            setCopied(true);  // Update state to indicate success
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch(err => {
            console.error("Failed to copy: ", err);
        });
    };

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleSearch = () => {
        setFilteredPersons(recentObituaries)
        console.log(`Search Keyword: ${query}`);
    };

    const handleApply = () => {
        alert(`Start Date: ${startDate}, End Date: ${endDate}`);
    };

    const ForArrow = () => {
        if (window.innerWidth >= 768) {
          return (
            <div className="obituary-details">
                <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
                <div className="obituary-page-recent-obituaries">
                    <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow'/></button>
                    <ul className="obituary-list">
                        {filteredPersons.map((obit, index) => (
                            <li 
                                key={index} 
                                className={`obituary-item ${obit.name === selectedPerson.name ? 'active' : ''}`} 
                                onClick={() => setSelectedPerson(obit)}
                            >
                                <div className="obituary-card" key={index}>
                                    <img src={obit.image} alt={obit.name} className="obituary-image" />
                                    <div className='obituary-card-info'>
                                        <span className='obituary-card-info-span-1'>In loving memory of</span>
                                        <h3 className='obituary-card-h3'>{obit.name}</h3>
                                        <p className='obituary-card-p'>{obit.dob} - {obit.dod}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow'/></button>
                </div>
            </div>
          )
        } else {
          return (
            <div className="obituary-details">
                <div className="obituary-page-recent-obituaries">
                    <ul className="obituary-list">
                        {filteredPersons.map((obit, index) => (
                            <li 
                                key={index} 
                                className={`obituary-item ${obit.name === selectedPerson.name ? 'active' : ''}`} 
                                onClick={() => setSelectedPerson(obit)}
                            >
                                <div className="obituary-card" key={index}>
                                    <img src={obit.image} alt={obit.name} className="obituary-image" />
                                    <div className='obituary-card-info'>
                                        <h3 className='obituary-card-h3'>{obit.name}</h3>
                                        <span className='obituary-card-p'>{obit.dob} - {obit.dod}</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='recent-obituaries-nav-button-container'>
                    <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
                    <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
                </div>
            </div>
          )
        }
    }
    return (
        <div className="obituary-page">
          <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <div className='obituary-space-between'>
            <h1 className='obituary-h1'>Obituary</h1>
            <span className='obituary-text'>Search for an obituary to find and remember your loved one. Enter a name to view details, tributes, and memories shared by family and friends.</span>
            <div className='obituary-side-by-side'>
                <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='obituary-search-bar' />
                <button onClick={handleSearch} className='obituary-search-button'>Search</button>
                <div className='obituary-inputContainer'>
                    <label className='obituary-label'>Start Date:</label>
                    <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className='obituary-inputDate'
                    />
                    <label className='obituary-label'>End Date:</label>
                    <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className='obituary-inputDate'
                    />
                    <button onClick={handleApply} className='obituary-applyButton'>Apply</button>
                </div>
            </div>
          </div>
          <ForArrow />
          <div className='obituary-second-part'>
            <img src='3.png' alt="obituary" />
            <div className='obituary-space-between2'>
                <div className='obituary-waze-link'>
                    <span>Waze location: <a className='obituary-link' href='https://waze.com/toapayohlor5s'>https://waze.com/</a></span>
                </div>
                <div>
                    <button onClick={handleCopy} className='obituary-share-btn'><img src='sharebtn.png' alt='sharebtn' /></button>
                    {copied && <p>Copied to clipboard!</p>}
                </div>
            </div>
          </div>
            
          <div className='obituary-bottom-info'>
            <span>五福 Union Casket</span>
            <span>电话Tel: 5353 8449</span>
            <span>手提 Mobile: 9118 8449</span>
            <span>地址 Address:  Blk 4, Lorong 8 Toa Payoh Industrial Park #01-1329</span>
            <Link to="/undertaker-profile" state={{ props: "五福 Union Casket" }}><img src='viewprofilebtn.png' className='obituary-view-profile' alt='viewpbtn' /></Link>
          </div>
        </div>
    );
};

export default Obituary;
